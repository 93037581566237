import { ErrorHandler, Injectable } from '@angular/core';

import * as Sentry from '@sentry/browser';


@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
    Sentry.init({
      dsn: 'https://5b41abb3de2d4c148401d643e9aad931@sentry.io/1894401',
    });
  }
  handleError(error) {

    const eventId = Sentry.captureException(error.originalError || error);
    // Sentry.showReportDialog({ eventId });
  }
}

