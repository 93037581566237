import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { MessageService } from 'primeng/api';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AuthService } from '../auth/shared/auth.service';

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
  private siteUrl = '/wp-json';
  // private siteUrl = 'https://bakercorp.articom.be/wp-json';

  constructor(
    private messageService: MessageService,
    private router: Router,
    private authService: AuthService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const headers =
      !!currentUser &&
      !!currentUser.token &&
      req.url !== this.authService.loginUrl &&
      { Authorization: `Bearer ${currentUser.token}` };

    const clonedReq = req.clone({
      setHeaders: headers,
      url: this.siteUrl + req.url,
    });

    return next.handle(clonedReq)
      .pipe(
        map((event: HttpEvent<any>) => {
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          let message: string | null = null;
          console.log(error);
          switch (error.status) {
            case 403:
              message = error.error.message || 'Some token problems';
              this.router.navigate(['/login']);
              break;
            case 401:
              message = 'Your session has been expired!';
              this.router.navigate(['/login']);
              break;
            default:
              if (error.error && error.error.message) {
                message = error.error.message;
              }
              break;
          }
          this.handleError(message);
          return throwError({ message, ok: false });
        }),
      );
  }

  handleError(message?: string) {
    this.messageService.add({
      key: 'error',
      severity: 'error',
      summary: 'Error!',
      detail: message || 'Something went wrong!',
    });
  }
}
