import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';

import { Error404Component } from './core/error404/error404.component';
import { Error500Component } from './core/error500/error500.component';

import { AuthGuardService as AuthGuard } from './shared/auth-guard.service';

const authModulePagesRoutes = ['login', 'my-account/lost-password'];
export const authModulePagesMatcher = (url: UrlSegment[]) => {
  if (url.length > 0) {
    const path = url.map(el => el.path).join('/');
    if (authModulePagesRoutes.some(route => route === path)) {
      return { consumed: [] };
    }
  }
  return null;
};

export const routes: Routes = [
  { path: 'login', redirectTo: 'my-account/login' },
  {
    path: '404',
    component: Error404Component,
    data: {
      title: 'Page 404',
    },
  },
  {
    path: '500',
    component: Error500Component,
    data: {
      title: 'Page 500',
    },
  },
  {
    path: 'my-account',
    loadChildren: './auth/auth.module#AuthModule',
  },
  {
    path: '',
    loadChildren: './shop/shop.module#ShopModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    loadChildren: './profile/profile.module#ProfileModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'technical-information',
    loadChildren: './pages/pages.module#PagesModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'cart',
    loadChildren: './cart/cart.module#CartModule',
    canActivate: [AuthGuard],
  },
  { path: '**', component: Error404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
