import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';

import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { UserShortDetails } from '../../shared/user.model';

// short details contains user Id which is required to get all details
// to display them as presets in profile components
@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private detailsUrl = '/wc/v3/customers';
  private shortDetailsUrl = '/wp/v2/users/me';
  private countriesUrl = '/wc/v3/data/countries';

  public userId: number = null;

  private profileDetails = new BehaviorSubject<any>(null);
  public profileDetails$ = this.profileDetails.asObservable();

  private profileShortDetails = new BehaviorSubject<UserShortDetails>(null);
  public profileShortDetails$ = this.profileShortDetails.asObservable();

  private countries = new BehaviorSubject<any[]>(null);
  public countries$ = this.countries.asObservable();

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
  ) {}

  public fetchDetails(id?: number) {
    if (!!this.userId || id) {
      this.http.get(`${this.detailsUrl}/${this.userId || id}`)
        .pipe(take(1))
        .subscribe((res) => {
          this.profileDetails.next(res);
        });
    }
  }
  public fetchCounties() {
    this.http.get(this.countriesUrl)
      .pipe(take(1))
      .subscribe((res: any[]) => {
        this.countries.next(res);
      });
  }

  public fetchShortDetails() {
    this.http.get(this.shortDetailsUrl)
      .pipe(take(1))
      .subscribe((res: UserShortDetails) => {
        this.profileShortDetails.next(res);

        if (res) {
          this.userId = res.id;
          this.fetchDetails(res.id);
        }
      });
  }

  public saveDetails(data) {
    this.http.put(`${this.detailsUrl}/${this.userId}`, data)
      .subscribe((res) => {
        this.profileDetails.next(res);
        this.messageService.add({
          key: 'success',
          severity: 'success',
          summary: 'Success!',
          detail: 'New data has been saved!',
        });
      });
  }


}
