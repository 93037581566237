import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { MessageService } from 'primeng/api';

import { map, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ProfileService } from '../../profile/shared/profile.service';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private cartUrl = '/wc/v2/cart/get';
  private addUrl = '/wc/v2/cart/add';
  private removeUrl = '/wc/v2/cart/cart-item';
  private updateUrl = '/wc/v2/cart/cart-item';
  private clearUrl = '/wc/v2/cart/clear';
  private placeOrderUrl = '/wc/v3/orders';
  private infoUrl = '/wc/v2/cart/get-info';
  private totalCostUrl = '/wc/v2/cart/totals';

  private loading = new BehaviorSubject<any>(true);
  public loading$ = this.loading.asObservable();

  private cartData = new BehaviorSubject<any>(null);
  public cartData$ = this.cartData.asObservable();

  private cartInfo = new BehaviorSubject<any>(null);
  public cartInfo$ = this.cartInfo.asObservable();

  private totalCost = new BehaviorSubject<any>(null);
  public totalCost$ = this.totalCost.asObservable();

  public cartFormLoading = new BehaviorSubject<any>(true);

  public viewBreakpoint = 990;

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private profileService: ProfileService,
  ) { }

  public fetchCart() {
    this.loading.next(true);

    this.http.get(this.infoUrl)
      .pipe(take(1))
      .subscribe(res => this.cartInfo.next(res));

    this.http.get(this.cartUrl)
      .pipe(take(1))
      .subscribe((res) => {
        const resArr = this.objectToArray(res).filter(item => !item.bundled_by);
        this.cartData.next(resArr);
      });

    this.http.post('/wc/v2/cart/calculate', null)
      .pipe(take(1))
      .subscribe((res) => {
        switch (res) {
          case 'Cart totals have been calculated.':
            this.http.get(this.totalCostUrl)
              .pipe(take(1))
              .subscribe((resp: any) => {
                if (resp.total) {
                  this.totalCost.next(resp.total);
                  this.loading.next(false);
                }
              });
            break;
          case 'No items in cart to calculate totals.':
            this.loading.next(false);
            this.totalCost.next(null);
            break;
          default:
            this.loading.next(false);
            this.totalCost.next(null);
        }
      });
  }

  public clearCart() {
    this.http.post(this.clearUrl, {})
      .pipe(take(1))
      .subscribe((res: string) => {
        if (res) {
          // this.messageService.add({
          //   key: 'success',
          //   severity: 'success',
          //   summary: 'Success!',
          //   detail: res || 'Cart is cleared!',
          // });
          this.fetchCart();
        }
      });
  }

  public sendOrder(shipping) {
    let billing = {};
    this.profileService.profileDetails$
      .pipe(take(1))
      .subscribe(profileDetails => billing = profileDetails.billing);

    const body = {
      billing,
      shipping,
      meta_data: [
        {
          key: 'lead_time',
          value: shipping.lead_time,
        },
      ],
      line_items: this.cartData.getValue().map((product) => {
        return {
          product_id: product.product_id,
          quantity: product.quantity,
        };
      }),
      set_paid: true,
      customer_id: this.profileService.userId,
    };

    return this.http.post(this.placeOrderUrl, body)
      .pipe(
        take(1),
        map((res) => {
          if (res) {
            this.messageService.add({
              key: 'success',
              severity: 'success',
              summary: 'Success!',
              detail: 'Order was placed!',
            });
            this.cartFormLoading.next(false);
            this.clearCart();
            return true;
          }
          return false;
        }),
      );
  }

  public add(id, quantity) {
    this.http.post(this.addUrl, { quantity, product_id: id })
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.messageService.add({
            key: 'success',
            severity: 'success',
            summary: 'Success!',
            detail: 'Product has been added!',
          });
        }
        this.fetchCart();
      });
  }

  public remove(key) {
    this.http.delete(this.removeUrl, { params: new HttpParams().set('cart_item_key', key) })
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.messageService.add({
            key: 'success',
            severity: 'success',
            summary: 'Success!',
            detail: 'Product has been removed!',
          });
        }
        this.fetchCart();
      });
  }

  public update(key: string, quantity: number) {
    this.http.post(this.updateUrl, { quantity, cart_item_key: key })
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.messageService.add({
            key: 'success',
            severity: 'success',
            summary: 'Success!',
            detail: 'Product has been updated!',
          });
        }
        this.fetchCart();
      });
  }

  public cartInvalid() {
    this.messageService.add({
      key: 'error',
      severity: 'error',
      summary: 'Error!',
      detail: 'Please, fill all required fields.',
    });
  }

  private objectToArray(obj: object): any[] {
    return Object.keys(obj).map(key => obj[key]);
  }
}
