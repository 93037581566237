import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';

interface Breadcrumb {
  label: string;
  url: string;
}

@Injectable(
  {
    providedIn: 'root'
  }
)
export class BreadcrumbsService {
  private breadcrumbs: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject(
    [{ label: 'Login', url: '/login' }]
  );
  public breadcrumbs$: Observable<Breadcrumb[]>;

  constructor(private router: Router) {
    this.breadcrumbs$ = this.breadcrumbs.asObservable();
  }

  listen() {
    this.router.events.subscribe((route: any) => {
      // console.log(route);
      if (route instanceof NavigationEnd) {
        // console.log(route);
        // console.log(router.urlAfterRedirects.split('/'));

        // breacrumbs
      }
    });
  }
}
