import { AfterViewInit, Component, OnInit } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';

import { BreadcrumbsService } from './shared/breadcrumbs.service';

import { ProfileService } from './profile/shared/profile.service';
import { AuthService } from './auth/shared/auth.service';
import { CartService } from './cart/shared/cart.service';

import 'plumber-engine/plumber-engine';
import { Router, NavigationEnd } from '@angular/router';

declare global {
  interface Window { Plumber: any; }
}

window.Plumber = window.Plumber || {};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {

  constructor(
    private router: Router,
    private breadcrumbs: BreadcrumbsService,
    private deviceDetector: DeviceDetectorService,
    private profileService: ProfileService,
    private authService: AuthService,
    private cartService: CartService,
  ) {}

  ngAfterViewInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (!!(window as any).ga) {
          (window as any).ga('set', 'page', event.urlAfterRedirects);
          (window as any).ga('send', 'pageview');
        }
      }
    });
  }

  ngOnInit() {
    this.breadcrumbs.listen();
    this.setDevice();
    if (this.authService.isLoggedIn) {
      this.cartService.fetchCart();
      this.profileService.fetchShortDetails();
    }
  }

  setDevice() {
    const isMobile = this.deviceDetector.isMobile();
    const isTablet = this.deviceDetector.isTablet();
    const isDesktop = this.deviceDetector.isDesktop();

    if (isMobile) document.body.classList.add('isMobile');
    if (isTablet) document.body.classList.add('isTablet');
    if (isDesktop) document.body.classList.add('isDesktop');
  }
}
