import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { MessageService } from 'primeng/api';

import { take, map, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { User } from '../../shared/user.model';

import { ProfileService } from '../../profile/shared/profile.service';
import { CartService } from '../../cart/shared/cart.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loginUrl = '/jwt-auth/v1/token';
  private resetPassUrl = '/apiuser/v1/forgotpwd/';

  private loginData = new BehaviorSubject<User>(null);
  public loginData$ = this.loginData.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private messageService: MessageService,
    private profileService: ProfileService,
    private cartService: CartService,
  ) {
    const str = localStorage.getItem('currentUser');
    if (str) {
      try {
        const obj = JSON.parse(str);
        if (obj.token) {
          this.loginData.next(obj);
        } else {
          throw new Error();
        }
      } catch (e) {
        console.warn('Token from local storage is invalid');
      }
    }
  }

  public logIn(username: string, password: string): Observable<any> {
    return this.http.post<any>(this.loginUrl, { username, password })
      .pipe(
        take(1),
        map((res: any) => {
          if (res && res.token) {
            localStorage.setItem('currentUser', JSON.stringify(res));
            this.loginData.next(res);

            this.cartService.fetchCart();
            this.profileService.fetchShortDetails();

            return true;
          }
          this.messageService.add({
            key: 'error',
            severity: 'error',
            summary: 'Error!',
            detail: 'Didnt get token!',
          });
          return false;
        }),
        catchError(error => of(false)),
      );
  }

  public logOut(): void {
    this.router.navigate(['login']);
    localStorage.removeItem('currentUser');
    this.loginData.next(null);
  }

  public get isLoggedIn(): boolean {
    if (localStorage.getItem('currentUser')) {
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      return currentUser.token !== null;
    }
    return false;
  }


  public resetPassword(email: string) {
    return this.http.get(`${this.resetPassUrl}${email}`)
      .pipe(
        take(1),
        map((res: any) => {
          if (res && res.message) {
            this.messageService.add({
              key: 'success',
              severity: 'success',
              summary: 'Success!',
              detail: res.message,
            });

            return true;
          }
          this.messageService.add({
            key: 'error',
            severity: 'error',
            summary: 'Error!',
            detail: 'Something went wrong!',
          });
          return false;
        }),
        catchError(error => of(false)),
      );
  }

}
