import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { DeviceDetectorService } from 'ngx-device-detector';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';

import { Error404Component } from './core/error404/error404.component';
import { Error500Component } from './core/error500/error500.component';

import { HttpClientInterceptor } from './shared/http.interceptor';

import { BreadcrumbsService } from './shared/breadcrumbs.service';

import { PrimengModule } from './shared/primeng.module';

import { SentryErrorHandler } from './shared/sentry.service';

@NgModule({
  declarations: [
    AppComponent,

    Error404Component,
    Error500Component,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PrimengModule,
  ],
  providers: [
    DeviceDetectorService,
    BreadcrumbsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
